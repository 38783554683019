import React from "react";
import AceHardware from "../../images/retailer-logos/ace-hardware.png";
import AffiliatedFoods from "../../images/retailer-logos/affiliated-foods.png";
import BentleysPetStuff from "../../images/retailer-logos/bentleys-pet-stuff.png";
import Citgo from "../../images/retailer-logos/citgo.png";
import CountyMarket from "../../images/retailer-logos/county-market.png";
import DoItBest from "../../images/retailer-logos/do-it-best.png";
import Exxon from "../../images/retailer-logos/exxon.png";
import ForwardCS from "../../images/retailer-logos/forward-convenience-stores.png";
import GarverFeeds from "../../images/retailer-logos/garver-feeds.png";
import Hardings from "../../images/retailer-logos/hardings-friendly-markets.png";
import IGA from "../../images/retailer-logos/iga.png";
import LightningQGNG from "../../images/retailer-logos/lightning-quick-gas-n-go.png";
import Marathon from "../../images/retailer-logos/marathon.png";
import Meijer from "../../images/retailer-logos/meijer.png";
import Mobil from "../../images/retailer-logos/mobil.png";
import Mosers from "../../images/retailer-logos/mosers-food.jpg";
import NeimansMarket from "../../images/retailer-logos/neimans-family-market.jpg";
import Shell from "../../images/retailer-logos/shell.png";
import SpartanNash from "../../images/retailer-logos/spartan-nash.png";
import Valero from "../../images/retailer-logos/valero.svg";
import {
  CustomerContainer,
  CustomerHeader,
  CustomerDivider,
  CustomerTitle,
  CustomerSlider,
  CustomerSlide,
  CustomerImage,
} from "./CustomerElements";
import "./_customer.scss";

export default function Customers() {
  return (
    <CustomerContainer id="customers">
      <CustomerHeader>
        <CustomerDivider className="desktop-only" />{" "}
        <CustomerTitle>Meet Our Customers</CustomerTitle> <CustomerDivider />
      </CustomerHeader>
      <CustomerSlider>
        <CustomerSlide>
          <CustomerImage src={Valero} alt="Valero Logo" />
          <CustomerImage src={Meijer} alt="Meijer Logo" />
          <CustomerImage src={SpartanNash} alt="Spartan Nash Logo" />
          <CustomerImage src={AceHardware} alt="Ace Hardware Logo" />
          <CustomerImage src={Marathon} alt="Marathon Logo" />
          <CustomerImage src={Mobil} alt="Mobil Logo" />
          <CustomerImage src={Shell} alt="Shell Logo" />
          <CustomerImage src={DoItBest} alt="Do It Best Logo" />
          <CustomerImage src={Citgo} alt="Citgo Logo" />
          <CustomerImage src={Valero} alt="Valero Logo" />
          <CustomerImage src={Exxon} alt="Exxon Logo" />
          <CustomerImage
            src={ForwardCS}
            alt="Forward Convenience Stores Logo"
          />
          <CustomerImage src={Hardings} alt="Hardings Logo" />
          <CustomerImage src={IGA} alt="IGA Logo" />
          <CustomerImage
            src={LightningQGNG}
            alt="Lightning Quick Gas-N-Go Logo"
          />
          <CustomerImage
            src={BentleysPetStuff}
            alt="Bentley's Pet Stuff Logo"
          />
          <CustomerImage src={AffiliatedFoods} alt="Affiliated Foods Logo" />
          <CustomerImage src={CountyMarket} alt="County Market's Logo" />
          <CustomerImage src={GarverFeeds} alt="Garver Feeds Logo" />
          <CustomerImage src={Mosers} alt="Mosers's Foods Logo" />
          <CustomerImage
            src={NeimansMarket}
            alt="Neiman's Family Market Logo"
          />
        </CustomerSlide>
        <CustomerSlide>
          <CustomerImage src={Valero} alt="Valero Logo" />
          <CustomerImage src={Meijer} alt="Meijer Logo" />
          <CustomerImage src={SpartanNash} alt="Spartan Nash Logo" />
          <CustomerImage src={AceHardware} alt="Ace Hardware Logo" />
          <CustomerImage src={Marathon} alt="Marathon Logo" />
          <CustomerImage src={Mobil} alt="Mobil Logo" />
          <CustomerImage src={Shell} alt="Shell Logo" />
          <CustomerImage src={DoItBest} alt="Do It Best Logo" />
          <CustomerImage src={Citgo} alt="Citgo Logo" />
          <CustomerImage src={Valero} alt="Valero Logo" />
          <CustomerImage src={Exxon} alt="Exxon Logo" />
          <CustomerImage
            src={ForwardCS}
            alt="Forward Convenience Stores Logo"
          />
          <CustomerImage src={Hardings} alt="Hardings Logo" />
          <CustomerImage src={IGA} alt="IGA Logo" />
          <CustomerImage
            src={LightningQGNG}
            alt="Lightning Quick Gas-N-Go Logo"
          />
          <CustomerImage
            src={BentleysPetStuff}
            alt="Bentley's Pet Stuff Logo"
          />
          <CustomerImage src={AffiliatedFoods} alt="Affiliated Foods Logo" />
          <CustomerImage src={CountyMarket} alt="County Market's Logo" />
          <CustomerImage src={GarverFeeds} alt="Garver Feeds Logo" />
          <CustomerImage src={Mosers} alt="Mosers's Foods Logo" />
          <CustomerImage
            src={NeimansMarket}
            alt="Neiman's Family Market Logo"
          />
        </CustomerSlide>
        <CustomerSlide>
          <CustomerImage src={Valero} alt="Valero Logo" />
          <CustomerImage src={Meijer} alt="Meijer Logo" />
          <CustomerImage src={SpartanNash} alt="Spartan Nash Logo" />
          <CustomerImage src={AceHardware} alt="Ace Hardware Logo" />
          <CustomerImage src={Marathon} alt="Marathon Logo" />
          <CustomerImage src={Mobil} alt="Mobil Logo" />
          <CustomerImage src={Shell} alt="Shell Logo" />
          <CustomerImage src={DoItBest} alt="Do It Best Logo" />
          <CustomerImage src={Citgo} alt="Citgo Logo" />
          <CustomerImage src={Valero} alt="Valero Logo" />
          <CustomerImage src={Exxon} alt="Exxon Logo" />
          <CustomerImage
            src={ForwardCS}
            alt="Forward Convenience Stores Logo"
          />
          <CustomerImage src={Hardings} alt="Hardings Logo" />
          <CustomerImage src={IGA} alt="IGA Logo" />
          <CustomerImage
            src={LightningQGNG}
            alt="Lightning Quick Gas-N-Go Logo"
          />
          <CustomerImage
            src={BentleysPetStuff}
            alt="Bentley's Pet Stuff Logo"
          />
          <CustomerImage src={AffiliatedFoods} alt="Affiliated Foods Logo" />
          <CustomerImage src={CountyMarket} alt="County Market's Logo" />
          <CustomerImage src={GarverFeeds} alt="Garver Feeds Logo" />
          <CustomerImage src={Mosers} alt="Mosers's Foods Logo" />
          <CustomerImage
            src={NeimansMarket}
            alt="Neiman's Family Market Logo"
          />
        </CustomerSlide>
        <CustomerSlide>
          <CustomerImage src={Valero} alt="Valero Logo" />
          <CustomerImage src={Meijer} alt="Meijer Logo" />
          <CustomerImage src={SpartanNash} alt="Spartan Nash Logo" />
          <CustomerImage src={AceHardware} alt="Ace Hardware Logo" />
          <CustomerImage src={Marathon} alt="Marathon Logo" />
          <CustomerImage src={Mobil} alt="Mobil Logo" />
          <CustomerImage src={Shell} alt="Shell Logo" />
          <CustomerImage src={DoItBest} alt="Do It Best Logo" />
          <CustomerImage src={Citgo} alt="Citgo Logo" />
          <CustomerImage src={Valero} alt="Valero Logo" />
          <CustomerImage src={Exxon} alt="Exxon Logo" />
          <CustomerImage
            src={ForwardCS}
            alt="Forward Convenience Stores Logo"
          />
          <CustomerImage src={Hardings} alt="Hardings Logo" />
          <CustomerImage src={IGA} alt="IGA Logo" />
          <CustomerImage
            src={LightningQGNG}
            alt="Lightning Quick Gas-N-Go Logo"
          />
          <CustomerImage
            src={BentleysPetStuff}
            alt="Bentley's Pet Stuff Logo"
          />
          <CustomerImage src={AffiliatedFoods} alt="Affiliated Foods Logo" />
          <CustomerImage src={CountyMarket} alt="County Market's Logo" />
          <CustomerImage src={GarverFeeds} alt="Garver Feeds Logo" />
          <CustomerImage src={Mosers} alt="Mosers's Foods Logo" />
          <CustomerImage
            src={NeimansMarket}
            alt="Neiman's Family Market Logo"
          />
        </CustomerSlide>
      </CustomerSlider>
    </CustomerContainer>
  );
}
