import React, {useState, useEffect} from 'react'
import { FaBars } from 'react-icons/fa'
import { Nav, NavbarContainer, LogoContainer, NavLogoLink, NavLogo, MobileIcon, NavMenu, 
    NavItem, NavLinks } from './NavbarElements'
import {IconContext} from 'react-icons/lib'
import {animateScroll as scroll} from 'react-scroll'
import Logo from '../../images/logo/cld-letters.png'

function Navbar({ toggle }) {
    const [scrollNav, setScrollNav] = useState(false)

    const changeNav = () => {
        if(window.scrollY >= 80) {
            setScrollNav(true)
        }
        else {
            setScrollNav(false)
        }
    }

    useEffect(() => {
        window.addEventListener('scroll', changeNav)
    }, [])

    const toggleHome = () => {
        scroll.scrollToTop();
    }

    return (
        <IconContext.Provider value={{color: '#fff'}}>
            <Nav scrollNav={scrollNav}>
                <NavbarContainer>
                    <LogoContainer>
                        <NavLogoLink to="/" onClick={toggleHome} >
                            <NavLogo src={Logo} alt="CLD logo" />
                        </NavLogoLink>
                        <MobileIcon onClick={toggle}>
                            <FaBars />
                        </MobileIcon>
                    </LogoContainer>
                    <NavMenu>
                        <NavItem>
                            <NavLinks to="company" smooth={true} duration={500} spy={true} exact='true' offset={-80}>
                                Company
                            </NavLinks>
                        </NavItem>
                        <NavItem>
                            <NavLinks to="events" smooth={true} duration={500} spy={true} exact='true' offset={-80}>Events</NavLinks>
                        </NavItem>
                        <NavItem>
                            <NavLinks to="customers" smooth={true} duration={500} spy={true} exact='true' offset={-80}>Customers</NavLinks>
                        </NavItem>
                        <NavItem>
                            <NavLinks to="contact-us" smooth={true} duration={500} spy={true} exact='true' offset={-80}>Contact Us</NavLinks>
                        </NavItem>
                    </NavMenu>
                </NavbarContainer>
            </Nav>
        </IconContext.Provider>

    )
}

export default Navbar;