import React, { useState } from "react";
import Logo from "../../images/logo/cld.png";
import { Button } from "../ButtonElements";
import {
  HeroContainer,
  HeroBg,
  ImgBg,
  HeroContent,
  HeroH1,
  // HeroP,
  HeroBtnWrapper,
} from "./HeroElements";

function HeroSection() {
  const [hover, setHover] = useState(false);

  const onHover = () => {
    setHover(!hover);
  };

  return (
    <HeroContainer id="home">
      <HeroBg>
        <ImgBg />
      </HeroBg>
      <HeroContent>
        <HeroH1>
          <img src={Logo} alt="test" width="250px" height="150px" />
        </HeroH1>
        <HeroBtnWrapper>
          <Button
            to="company"
            onMouseEnter={onHover}
            onMouseLeave={onHover}
            primary="true"
            dark="true"
            smooth={true}
            duration={500}
            spy={true}
            exact="true"
            offset={-80}
          >
            Learn more
          </Button>
        </HeroBtnWrapper>
      </HeroContent>
    </HeroContainer>
  );
}

export default HeroSection;
