import styled from "styled-components";

export const ContactUsContainer = styled.div`
  color: #4d4d4d;
  padding: 3rem 5rem 5rem;
  width: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;

  @media screen and (max-width: 1244px) {
    flex-direction: column;
    max-width: 100%;
    padding-box: 5rem;
    padding-inline: 1rem;
  }
`;

export const ContactUsInfoContainer = styled.div`
  margin-right: 5rem;

  @media screen and (max-width: 1244px) {
    margin-right: 0;
    margin-bottom: 5rem;
  }
`;

export const ContactUsHeader = styled.div`
  margin-bottom: 2rem;
`;

export const ContactUsTitle = styled.h1`
  font-size: 64px;
  text-transform: uppercase;
  text-align: center;

  @media screen and (max-width: 1244px) {
    font-size: 50px;
  }
`;

export const ContactUsDivider = styled.hr`
  border: 2px solid #4d4d4d;
  width: 85%;
  margin-inline: auto;

  @media screen and (max-width: 1244px) {
    width: 30.86%;
    border: 2px solid #3cb64b;
  }
`;

export const ContactUsContactInfo = styled.div`
  margin-bottom: 2rem;
`;

export const ContactUsEmployeeTitle = styled.h2`
  margin-bottom: 0.5rem;
`;

export const ContactUsEmail = styled.p`
  font-size: 1.5rem;
  margin-bottom: 0.5rem;

  @media screen and (max-width: 1244px) {
    font-size: 1.2rem;
  }
`;

export const ContactUsEmailAddress = styled.a`
  color: #4d4d4d;
  text-decoration: none;

  &:hover {
    color: #3cb64b;
  }
`;

export const ContactUsPhone = styled.p`
  font-size: 1.5rem;

  @media screen and (max-width: 1244px) {
    font-size: 1.2rem;
  }
`;

export const ContactUsPhoneNumber = styled.a`
  color: #4d4d4d;
  text-decoration: none;

  &:hover {
    color: #3cb64b;
  }
`;

export const ContactUsLogoContainer = styled.div`
  text-align: center;
`;

export const ContactUsLogo = styled.img`
  height: 250px;

  @media screen and (max-width: 1244px) {
    height: 150px;
  }
`;

export const ContactUsFormContainer = styled.div`
  margin-left: 5rem;
  height: 725px;
  width: 465px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 4px solid #475daa;
  border-radius: 5px;
  padding-bottom: 5rem;
  padding-inline: 1rem;

  @media screen and (max-width: 1244px) {
    margin-left: 0;
    max-width: 100%;
  }
`;

export const ContactUsFormTitle = styled.span`
  font-weight: bold;
  font-size: 64px;
  display: flex;
  align-items: center;

  @media screen and (max-width: 1244px) {
    font-size: 36px;
    white-space: nowrap;
  }
`;

export const ContactUsForm = styled.form`
  width: 100%;
`;

export const ContactUsFormGroup = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const ContactUsFormInputLabel = styled.label`
  font-weight: bold;
  margin-bottom: 0.15rem;
`;

export const ContactUsFormInput = styled.input`
  border: 2px solid #475daa;
  border-radius: 5px;
  margin-bottom: 2rem;
  padding: 0.5rem;
`;

export const ContactUsFormTextArea = styled.textarea`
  border: 2px solid #475daa;
  border-radius: 5px;
  max-height: 10rem;
  max-width: 100%;
  min-height: 10rem;
  padding: 0.5rem;
`;

export const ContactUsFormSuccessMessage = styled.p`
  color: green;
  font-size: 13px;
  margin-top: 0.5rem;
`;

export const ContactUsFormFailedMessage = styled.p`
  color: red;
  font-size: 13px;
  margin-top: 0.5rem;
`;
