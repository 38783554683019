import React from "react";
// import Logo from '../../images/undraw_profile_pic_ic-5-t (1).svg';
import CLPT from "../..//images/product-logos/clpt.png";
import GLPT from "../../images/product-logos/glpt.png";
import WildBone from "../../images/product-logos/wildboneco.png";
import CooperStreet from "../../images/product-logos/cooperstreet.svg";
import LovingPets from "../../images/product-logos/loving-pets.png";
import ForeignCandy from "../../images/product-logos/foreign-candy-co.png";
// import MillionDollarPetTreats from "../../images/product-logos/million-dollar-pet-treats.png";
import CLDLogo from "../../images/logo/cld.png";
import {
  CompanyContainer,
  CompanyHeader,
  CompanyLogo,
  CompanyLogoContainer,
  CompanyTitle,
  CompanyDivider,
  CompanyDescription,
  ProductsContainer,
  ProductsHeader,
  ProductsTitle,
  ProductImage,
  ProductsDiv,
  ProductsDivider,
} from "./CompanyElements";

function Company() {
  // const employees = [
  //     {
  //         image: Logo,
  //         name: "Mike Ovadek",
  //         title: "National New Business Development"
  //     },
  //     {
  //         image: Logo,
  //         name: "Tiffany Ward",
  //         title: "National Sales"
  //     },
  //     {
  //         image: Logo,
  //         name: "Scott Ward",
  //         title: "Account Manager"
  //     },
  //     {
  //         image: Logo,
  //         name: "Max Farner",
  //         title: "Account Manager"
  //     }
  // ]

  return (
    <CompanyContainer id="company">
      <CompanyHeader>
        <CompanyTitle>Company</CompanyTitle> <CompanyDivider />
      </CompanyHeader>
      <CompanyLogoContainer>
        <CompanyLogo src={CLDLogo} alt="Cost Less Distributing Logo" />
      </CompanyLogoContainer>
      <CompanyDescription>
        <div>
          With over 35 years in the pet treat and pet food industry, on both the
          wholesale and retail level, we have a keen understanding of what pet
          owners want. Today more than ever, product quality is of utmost
          importance. We hear it all the time; many people treat their pets
          better than they treat their kids. This is why we are committed to
          offering the best and safest treats available. We also want to offer
          treats that pet parents will purchase over and over again. <br />{" "}
          <br />
          We have strong relationships with our suppliers in Brazil, Colombia,
          and the USA. We have been with some of them for over 20 years. They
          have a good understanding of what's important to us, product quality,
          timely delivery, and competitive pricing. As a result, we can offer
          our customers a high quality product, in a timely manner, at a very
          competitive price. <br /> <br />
          In addition to our pet treat program, we now offer a high quality cell
          phone charger cable, the incredible Cooper Street cookies for humans,
          and will soon introduce our own small batch, gourmet popcorn. <br />{" "}
          <br />
          We are a family owned business; most of us are former athletes. We
          understand the concept of teamwork. Everyone here has a role, but, we
          all pitch in with whatever it takes to get the job done.
        </div>
      </CompanyDescription>
      <ProductsContainer>
        <ProductsDiv>
          <ProductsHeader>
            <ProductsDivider />{" "}
            <ProductsTitle> BRANDS WE DISTRIBUTE </ProductsTitle>{" "}
            <ProductsDivider />
          </ProductsHeader>
          <div>
            <ProductImage src={GLPT} alt="Great Lakes Pet Treats Logo" />
            {/* <ProductImage
              src={MillionDollarPetTreats}
              alt="Million Dollar Pet Treats Logo"
            /> */}
            <ProductImage src={CLPT} alt="Cost Less Pet Treats Logo" />
            <ProductImage src={WildBone} alt="Wild Bone Co. Logo" />
            <ProductImage src={CooperStreet} alt="Cooper Street Logo" />
            <ProductImage
              src={ForeignCandy}
              alt="The Foreign Candy Company Logo"
            />
            <ProductImage src={LovingPets} alt="Loving Pets Logo" />
          </div>
        </ProductsDiv>
      </ProductsContainer>
      {/* <EmployeeContainer>
                {employees.map((employee) => (
                <EmployeeInfo key={employee.name}>
                    <EmployeeImage src={employee.image} alt="test" />
                    <EmployeeName>{employee.name}</EmployeeName>
                    <EmployeeTitle>{employee.title}</EmployeeTitle>
                </EmployeeInfo>
                ))}
            </EmployeeContainer> */}
    </CompanyContainer>
  );
}

export default Company;
