import styled from "styled-components";

export const CompanyContainer = styled.div`
  color: #4d4d4d;
  padding: 3rem 5rem 5rem;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  background: #ffffff;

  @media screen and (max-width: 950px) {
    padding-inline: 3rem;
  }
`;

export const CompanyHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 2rem;

  @media screen and (max-width: 950px) {
    flex-direction: column;
  }
`;

export const CompanyLogoContainer = styled.div`
  text-align: center;
  margin-bottom: 2rem;
`;

export const CompanyLogo = styled.img`
  width: 250px;
  height: 150px;
`;

export const CompanyTitle = styled.h1`
  font-size: 64px;
  text-transform: uppercase;
  margin-right: 5rem;

  @media screen and (max-width: 950px) {
    margin-right: 0;
  }
`;

export const CompanyDivider = styled.hr`
  // border-top: 5px solid #475DAA;
  border: 2px solid #3cb64b;
  width: 100%;

  @media screen and (max-width: 950px) {
    width: 50%;
  }
`;

export const CompanyDescription = styled.div`
  font-size: 1.2rem;
  padding-inline: 2rem;
  margin-bottom: 2rem;

  @media screen and (max-width: 950px) {
    text-align: center;
    padding-inline: 0;
  }
`;

// export const EmployeeContainer = styled.div`
//     display: flex;
//     justify-content: center;
//     padding: 2rem;
// `

// export const EmployeeInfo = styled.div`
//     display: flex;
//     align-items: center;
//     flex-direction: column;
//     margin: 2rem 2rem 0;
//     font-size: 18px;
//     text-align: center;
// `

// export const EmployeeImage = styled.img`
//     max-width: 10rem;
//     max-height: 10rem;
//     margin-bottom: 1rem;
// `

// export const EmployeeName = styled.p`
//     color: #475DAA;
//     margin-bottom: 1rem;
//     font-weight: bold;
//     font-size: 24px;
// `

export const EmployeeTitle = styled.p`
  max-width: 15rem;
  font-weight: bold;
  color: #475daa;
  opacity: 0.75;
`;

export const ProductsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ProductsHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 2rem;
`;

export const ProductsTitle = styled.h3`
  margin-inline: 2rem;
  width: 35%;

  @media screen and (max-width: 1224px) {
    margin-inline: 0;
    width: 100%;
  }
`;

export const ProductsDiv = styled.div`
  width: 100%;
  text-align: center;
`;

export const ProductImage = styled.img`
  height: 75px;
  margin-inline: 1rem;

  @media screen and (max-width: 1224px) {
    margin-bottom: 1rem;
    height: 50px;
    margin-inline: 0.5rem;
  }
`;

export const ProductsDivider = styled.hr`
  border: 2px solid #eaeaea;
  width: 100%;

  @media screen and (max-width: 950px) {
    display: none;
  }
`;
