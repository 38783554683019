import React, { useState, useRef } from "react";
// import Logo from "../../images/logo/cld.png";
import {
  ContactUsContainer,
  // ContactUsHeader,
  // ContactUsInfoContainer,
  // ContactUsTitle,
  // ContactUsDivider,
  // ContactUsContactInfo,
  // ContactUsEmployeeTitle,
  ContactUsEmail,
  ContactUsEmailAddress,
  // ContactUsPhone,
  // ContactUsPhoneNumber,
  // ContactUsLogoContainer,
  // ContactUsLogo,
  ContactUsFormContainer,
  ContactUsFormTitle,
  ContactUsForm,
  ContactUsFormGroup,
  ContactUsFormInputLabel,
  ContactUsFormInput,
  ContactUsFormTextArea,
  ContactUsFormSuccessMessage,
  ContactUsFormFailedMessage,
} from "./ContactUsElements";
// import { FaPaperPlane } from "react-icons/fa";
import { SubmitButton } from "../ButtonElements";
import { send } from "emailjs-com";

function ContactUs() {
  const nameRef = useRef(null);
  const emailRef = useRef(null);
  const phoneRef = useRef(null);
  const messageRef = useRef(null);
  const [hover, setHover] = useState(false);
  const [sentStatus, setSentStatus] = useState(0);
  const [senderName, setSenderName] = useState("");
  const [senderEmail, setSenderEmail] = useState("");
  const [senderPhone, setSenderPhone] = useState("");
  const [senderMessage, setSenderMessage] = useState("");

  const onHover = () => {
    setHover(!hover);
  };

  const sendMessage = (e) => {
    e.preventDefault();
    send(
      "service_pgzrkgi",
      "template_hx5t3rf",
      {
        senderName,
        senderEmail,
        senderPhone,
        senderMessage,
      },
      "Yz7kqenYj8J84VCNJ"
    )
      .then((response) => {
        setSentStatus(response.status);

        if (response.status === 200) {
          nameRef.current.value = "";
          emailRef.current.value = "";
          phoneRef.current.value = "";
          messageRef.current.value = "";
        }
      })
      .catch((err) => {
        setSentStatus(err.status);
      });
  };

  return (
    <ContactUsContainer id="contact-us">
      {/* <ContactUsInfoContainer>
        <ContactUsHeader>
          <ContactUsTitle>Contact Us</ContactUsTitle>
          <ContactUsDivider />
        </ContactUsHeader>
        <ContactUsContactInfo>
          <ContactUsEmployeeTitle>
            Mike Ovadek - VP of Sales
          </ContactUsEmployeeTitle>
          <ContactUsEmail>
            Email:{" "}
            <ContactUsEmailAddress href="mailto:mike@costlessdistributing.com">
              mike@costlessdistributing.com
            </ContactUsEmailAddress>
          </ContactUsEmail>
          <ContactUsPhone>
            Phone:{" "}
            <ContactUsPhoneNumber href="tel:+18108450536">
              (810) 845-0536
            </ContactUsPhoneNumber>
          </ContactUsPhone>
          <br />
          <ContactUsEmployeeTitle>
            Steve Dunnegan - Western U.S. Regional Sales Manager
          </ContactUsEmployeeTitle>
          <ContactUsEmail>
            Email:{" "}
            <ContactUsEmailAddress href="mailto:sdunnegan@costlessdistributing.com">
              sdunnegan@costlessdistributing.com
            </ContactUsEmailAddress>
          </ContactUsEmail>
          <ContactUsPhone>
            Phone:{" "}
            <ContactUsPhoneNumber href="tel:+12096048599">
              (209) 604-8599
            </ContactUsPhoneNumber>
          </ContactUsPhone>
          <br />
          <ContactUsEmployeeTitle>
            Max Farner - Senior Account Rep., Western / Northern Michigan
          </ContactUsEmployeeTitle>
          <ContactUsEmail>
            Email:{" "}
            <ContactUsEmailAddress href="mailto:mfarner@costlessdistributing.com">
              mfarner@costlessdistributing.com
            </ContactUsEmailAddress>
          </ContactUsEmail>
          <ContactUsPhone>
            Phone:{" "}
            <ContactUsPhoneNumber href="tel:+18102471932">
              (810) 247-1932
            </ContactUsPhoneNumber>
          </ContactUsPhone>
          <br />
          <ContactUsEmployeeTitle>
            Tiffany Ward - Inside Sales Manager
          </ContactUsEmployeeTitle>
          <ContactUsEmail>
            Email:{" "}
            <ContactUsEmailAddress href="mailto:tward@costlessdistributing.com">
              tward@costlessdistributing.com
            </ContactUsEmailAddress>
          </ContactUsEmail>
          <ContactUsPhone>
            Phone:{" "}
            <ContactUsPhoneNumber href="tel:+18107154500">
              (810) 715-4500
            </ContactUsPhoneNumber>
          </ContactUsPhone>
          <br />
          <ContactUsEmployeeTitle>
            Gary Taylor - South East Michigan Account Manager
          </ContactUsEmployeeTitle>
          <ContactUsEmail>
            Email:{" "}
            <ContactUsEmailAddress href="mailto:gtaylor@costlessdistributing.com">
              gtaylor@costlessdistributing.com
            </ContactUsEmailAddress>
          </ContactUsEmail>
          <ContactUsPhone>
            Phone:{" "}
            <ContactUsPhoneNumber href="tel:+18105159575">
              (810) 515-9575
            </ContactUsPhoneNumber>
          </ContactUsPhone>
        </ContactUsContactInfo>
        <ContactUsLogoContainer>
          <ContactUsLogo src={Logo} alt="Cost Less Distributing Logo" />
        </ContactUsLogoContainer>
      </ContactUsInfoContainer> */}
      <ContactUsFormContainer>
        <ContactUsFormTitle>
          {/* <FaPaperPlane style={{ height: "50px", marginRight: "0.05rem" }} />{" "} */}
          Contact Us
        </ContactUsFormTitle>
        <ContactUsEmail>
          Email:{" "}
          <ContactUsEmailAddress href="mailto:info@costlessdistributing.com">
            info@costlessdistributing.com
          </ContactUsEmailAddress>
        </ContactUsEmail>
        <ContactUsForm onSubmit={(e) => sendMessage(e)}>
          <ContactUsFormGroup>
            <>
              <ContactUsFormInputLabel htmlFor="name">
                Name<span style={{ color: "red" }}>*</span>
              </ContactUsFormInputLabel>
              <ContactUsFormInput
                type="text"
                className="form-control"
                id="name"
                placeholder="Enter name"
                ref={nameRef}
                onChange={(e) => setSenderName(e.target.value)}
                required
              />
            </>
            <>
              <ContactUsFormInputLabel htmlFor="email">
                Email address<span style={{ color: "red" }}>*</span>
              </ContactUsFormInputLabel>
              <ContactUsFormInput
                type="email"
                className="form-control"
                pattern="[^@ \t\r\n]+@[^@ \t\r\n]+\.[^@ \t\r\n]+"
                id="email"
                placeholder="Enter email"
                ref={emailRef}
                onChange={(e) => setSenderEmail(e.target.value)}
                required
              />
            </>
            <>
              <ContactUsFormInputLabel htmlFor="phone">
                Phone Number
              </ContactUsFormInputLabel>
              <ContactUsFormInput
                type="tel"
                className="form-control"
                maxLength="10"
                id="phone"
                placeholder="Enter Phone Number"
                ref={phoneRef}
                onChange={(e) => setSenderPhone(e.target.value)}
              />
            </>
            <>
              <ContactUsFormInputLabel htmlFor="message">
                Message<span style={{ color: "red" }}>*</span>
              </ContactUsFormInputLabel>
              <ContactUsFormTextArea
                className="form-control"
                id="message"
                rows="3"
                ref={messageRef}
                onChange={(e) => setSenderMessage(e.target.value)}
                required
              />
            </>
            <>
              {sentStatus === 200 && (
                <ContactUsFormSuccessMessage>
                  Message sent successfully!
                </ContactUsFormSuccessMessage>
              )}
              {sentStatus !== 200 && sentStatus !== 0 && (
                <ContactUsFormFailedMessage>
                  Message failed to send. Please email
                  mike@costlessdistributing.com directly.
                </ContactUsFormFailedMessage>
              )}
            </>
            <>
              <SubmitButton onMouseEnter={onHover} onMouseLeave={onHover}>
                Submit
              </SubmitButton>
            </>
          </ContactUsFormGroup>
        </ContactUsForm>
      </ContactUsFormContainer>
    </ContactUsContainer>
  );
}
export default ContactUs;
