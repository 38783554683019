import React from "react";
// import { FaFacebook, FaInstagram } from 'react-icons/fa'
import {
  FooterContainer,
  FooterWrap,
  SocialMedia,
  SocialMediaWrap,
  SocialLogo,
  FooterLogo,
  WebsiteRights,
  SocialIcons,
  //   SocialIconLink,
} from "./FooterElements";
import { animateScroll as scroll } from "react-scroll";
import Logo from "../../images/logo/cld-letters.png";

function Footer() {
  const toggleHome = () => {
    scroll.scrollToTop();
  };

  return (
    <FooterContainer>
      <FooterWrap>
        <SocialMedia>
          <SocialMediaWrap>
            <SocialLogo to="/" onClick={toggleHome}>
              <FooterLogo src={Logo} alt="CLD logo" />
            </SocialLogo>
            <WebsiteRights>
              Cost Less Distributing © {new Date().getFullYear()} All rights
              reserved.
            </WebsiteRights>
            <SocialIcons>
              {/* <SocialIconLink href="//www.facebook.com" target="_blank" ara-label="Facebook"> <FaFacebook stroke='#f26f25' fill="#f26f25" /></SocialIconLink> */}
              {/* <SocialIconLink href="//www.instagram.com" target="_blank" ara-label="Instagram"> <FaInstagram stroke='#f26f25' fill="#f26f25" /></SocialIconLink> */}
            </SocialIcons>
          </SocialMediaWrap>
        </SocialMedia>
      </FooterWrap>
    </FooterContainer>
  );
}

export default Footer;
