import styled from "styled-components"
import {Link} from "react-router-dom"

export const FooterContainer = styled.footer`
    background: #475DAA;
`

export const FooterWrap = styled.div`
    /* padding: 48px 24px; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 1100px;
    margin: 0 auto;
`

export const SocialMedia = styled.section`
    max-width: 1000px;
    width: 100%;
`

export const SocialMediaWrap = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1100px;
    margin: 1rem auto;

    @media screen and (max-width: 820px) {
        flex-direction: column;
    }
`

export const SocialLogo = styled(Link)`
    color: #fff;
    justify-self: start;
    cursor: pointer;
    text-decoration: none;
    font-size: 1.5rem;
    display: flex;
    align-items: center;
    font-weight: bold;
    width: 8rem;
    height: 4rem;
    filter: drop-shadow(-2px 2px 5px rgba(0, 0, 0, 0.5));
    
    @media screen and (max-width: 820px) {
        margin-bottom: 0.5rem;
    }
`

export const FooterLogo = styled.img`
    height: 6rem;

    @media screen and (max-width: 820px) {
        height: 5rem;
    }
`

export const WebsiteRights = styled.small`
    color: #fff;

    @media screen and (max-width: 820px) {
        margin-bottom: 1rem;
    }
`

export const SocialIcons = styled.div`
    display: flex;
    align-items: center;
    filter: drop-shadow(-2px 2px 5px rgba(0, 0, 0, 0.5));
`

export const SocialIconLink = styled.a`
    font-size: 24px;
    margin-inline: 5px;
`