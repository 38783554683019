import styled from "styled-components";
// import { MdArrowForward, MdKeyboardArrowRight } from "react-icons/md";
import BackgroundImg from "../../images/hero-background-image.png";

export const HeroContainer = styled.div`
  // background: #0c0c0c;
  // background-color: #0c0c0c;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 30px;
  height: 969px;
  position: relative;
  z-index: 1;

  // :before {
  //     content: '';
  //     position: absolute;
  //     top: 0;
  //     left: 0;
  //     right: 0;
  //     bottom: 0;
  //     /* background: linear-gradient(180deg, rgba(0,0,0,00.2), 0%, rgba(0, 0, 0, 0.6) 100%), linear-gradient(180deg, rgba(0, 0, 0, 0.2) 0%, transparent 100%); */
  //     background: linear-gradient(180deg, rgba(46,53,68,00.2), 0%, rgba(46,53,68, 0.6) 100%), linear-gradient(180deg, rgba(46,53,68, 0.2) 0%, transparent 100%);
  //     z-index: 2;
  // }

  @media screen and (max-width: 768px) {
    height: 883px;
  }
`;

export const HeroBg = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

export const ImgBg = styled.div`
  width: 100%;
  height: 100%;
  background-image: url(${BackgroundImg});
  background-size: cover;
  background-color: #475daa;
  margin-left: auto;
`;

export const HeroContent = styled.div`
  z-index: 3;
  max-width: 1200px;
  position: absolute;
  padding: 8px 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const HeroH1 = styled.h1`
  /* color: #fff; */
  color: #3eb54b;
  font-size: 48px;
  text-align: center;
  filter: drop-shadow(2px 4px 6px rgba(0, 0, 0, 0.5));

  @media screen and (max-width: 768px) {
    font-size: 40px;
  }

  @media screen and (max-width: 480px) {
    font-size: 32px;
  }
`;

export const HeroP = styled.p`
  margin-top: 24px;
  color: #fff;
  font-size: 24px;
  text-align: center;
  max-width: 600px;

  @media screen and (max-width: 768px) {
    font-size: 24px;
  }

  @media screen and (max-width: 480px) {
    font-size: 18px;
  }
`;

export const HeroBtnWrapper = styled.div`
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

// export const ArrowForward = styled(MdArrowForward)`
//   color: #3eb54b;
//   margin-left: 8px;
//   font-size: 20px;
// `;

// export const ArrowRight = styled(MdKeyboardArrowRight)`
//   color: #ffffff;
//   margin-left: 8px;
//   font-size: 20px;
// `;
