import styled from "styled-components"

export const CustomerContainer = styled.div`
    padding: 3rem 5rem;
    // height: 425px;
    // max-height: 425px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    background: #475DAA;

    @media screen and (max-width: 1244px) {
        padding-inline: 4rem;
    }
`

export const CustomerHeader = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 5rem;

    @media screen and (max-width: 1244px) {
        flex-direction: column;
    }
`

export const CustomerDivider = styled.hr`
    border: 2px solid white;
    width: 25%;

    @media screen and (max-width: 1244px) {
        width: 50%;
    }
`

export const CustomerTitle = styled.h1`
    font-size: 32px;
    text-transform: uppercase;
    margin: 0 5rem;
    color: white;

    @media screen and (max-width: 1244px) {
        margin: 0;
        text-align: center;
    }
`

export const CustomerSlider = styled.div`
    height: 150px;
    display: flex;
    overflow: hidden;
    position: relative;
    width: 100%;

    &::before, &::after {
        height: 150px;
        width: 250px;
        position: absolute;
        content: "";
        background: linear-gradient(to right, #475DAA 25%, rgba(255, 255, 255, 0) 100%);
        z-index: 2;
        
        @media screen and (max-width: 767px) {
            display: none;
        }
    }

    &::before {
        top: 0;
        left: 0;
    }

    &::after {
        top: 0;
        right: 0;
        transform: rotateZ(180deg);
    }
`

export const CustomerSlide = styled.div`
    height: 150px;
    width: auto;
    display: flex;
    align-items: center;
    animation: slideshow 60s linear infinite;
    filter: drop-shadow(-2px 2px 5px rgba(0, 0, 0, 0.5));
`

export const CustomerImage = styled.img`
    height: 100px;
    max-width: 350px;
    padding: 0 3rem;
`

