import React from "react";
import {
  SidebarContainer,
  Icon,
  CloseIcon,
  SidebarWrapper,
  SidebarMenu,
  SidebarLink,
} from "./SidebarElements";

function Sidebar({ isOpen, toggle }) {
  return (
    <SidebarContainer isOpen={isOpen} onClick={toggle}>
      <Icon onClick={toggle}>
        <CloseIcon />
      </Icon>
      <SidebarWrapper>
        <SidebarMenu>
          <SidebarLink to="company" onClick={toggle}>
            {" "}
            Company{" "}
          </SidebarLink>
          <SidebarLink to="events" onClick={toggle}>
            {" "}
            Events{" "}
          </SidebarLink>
          <SidebarLink to="customers" onClick={toggle}>
            {" "}
            Customers{" "}
          </SidebarLink>
          <SidebarLink to="contact-us" onClick={toggle}>
            {" "}
            Contact Us{" "}
          </SidebarLink>
        </SidebarMenu>
      </SidebarWrapper>
    </SidebarContainer>
  );
}

export default Sidebar;
