import React, { useState } from 'react'
import Sidebar from '../components/Sidebar'
import Navbar from '../components/Navbar'
import HeroSection from '../components/HeroSection'
import Company from '../components/Company'
import Customers from '../components/Customers'
import Events from '../components/Events'
import ContactUs from '../components/ContactUs'
import Footer from '../components/Footer'

function Home() {
    const[isOpen, setIsOpen] = useState(false)

    const toggle = () => {
        setIsOpen(!isOpen)
    }

    return (
        <>
            <Sidebar isOpen={isOpen} toggle={toggle}/>
            <Navbar toggle={toggle}/>
            <HeroSection />
            <Company />
            <Events />
            <Customers />
            <ContactUs />
            <Footer />
        </>
    )
}

export default Home
