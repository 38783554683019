import styled from "styled-components";

export const EventContainer = styled.div`
  color: #4d4d4d;
  padding: 3rem 5rem 5rem;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  /* background: #475DAA; */
  background: #fcfcfc;

  @media screen and (max-width: 950px) {
    padding: 3rem 3rem 5rem;
  }
`;

export const EventHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 2rem;

  @media screen and (max-width: 950px) {
    flex-direction: column-reverse;
  }
`;

export const EventTitle = styled.h1`
  font-size: 64px;
  text-transform: uppercase;
  margin-left: 5rem;

  @media screen and (max-width: 950px) {
    margin-left: 0;
  }
`;

export const EventDivider = styled.hr`
  border: 2px solid #3cb64b;
  width: 100%;

  @media screen and (max-width: 950px) {
    width: 50%;
  }
`;

export const DeviceEventsContainer = styled.div`
  margin-inline: auto;

  @media screen and (min-width: 851px) {
    display: none;
  }
`;

export const DeviceEventContainer = styled.div`
  &:not(:last-child) {
    border-bottom: 1px solid #000;
  }
  padding-bottom: 1rem;
  margin-bottom: 1rem;
  display: grid;
  row-gap: 1rem;
`;

export const DeviceRowContainer = styled.div`
  display: flex;
  align-items: center;
  font-size: 12px;
  text-align: right;
`;

export const EventNameTitle = styled.div`
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  margin-right: auto;
  padding-right: 0.5rem;
`;

export const EventLocationTitle = styled.div`
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  margin-right: auto;
  padding-right: 0.5rem;
`;
export const EventDateTitle = styled.div`
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  margin-right: auto;
  padding-right: 0.5rem;

  @media screen and (min-width: 851px) {
    padding-right: 1rem;
  }
`;
export const EventBoothTitle = styled.div`
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  margin-right: auto;
  padding-right: 0.5rem;
`;

export const EventTableContainer = styled.div`
  @media screen and (max-width: 850px) {
    display: none;
  }
`;
